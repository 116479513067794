<template>
  <!-- 章节管理组件 -->
  <div>
    <a-button type="primary" @click="addCatalogueClick" style="margin-bottom:20px">添加章节</a-button>
    <div class="list" v-for="(item, index) in catalogueArr" :key="item.catalogueNo">
      <div class="big_list">
        <div class="list_left">
          {{ index + 1 }}、<span>{{ item.title }}</span>
        </div>
        
        <div class="list_right">
          <template>
            <a-icon type="edit" class="my_icon" @click="editClick(index, item)" />
          </template>
          <a-icon type="delete" class="my_icon" @click="catalogueDeleteClick(item)" />
        </div>
      </div>

      <a-form class="col drag-form">
        <div class="small_list" v-for="(item, index) in item.lessons" :key="index">
          <div class="small_list_left">{{ item }}</div>
        </div>
      </a-form>
    </div>
    <a-modal
        title="添加编辑章节"
        :visible="modelShow"
        :footer="null"
        :destroy-on-close="true"
        @cancel="handleCancel"
      >
        <a-form-model
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="章节名称" ref="title" prop="title">
            <a-input v-model="ruleForm.title" placeholder="请输入章节名称" />
          </a-form-model-item>
          <a-form-model-item label="排序" ref="sort" prop="sort">
            <a-input v-model="ruleForm.sort" placeholder="数字越大，顺序越靠前" />
          </a-form-model-item>
          
          <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button type="primary" @click="submitForm('ruleForm')">
              确定
            </a-button>
            <a-button style="margin-left: 10px" @click="handleCancel">
              取消
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
  </div>
</template>

<script>
import UpdataImg from "@/components/file/updataImg";
import {
    studyTagCatalogueDel,
    studyCatalogueSave,
    studyCatalogueEdit,
    studyCatalogueList,
} from "@/request/api/coursManage";
import { message, Modal } from "ant-design-vue";
const validateSort = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入排序'));
  } else if (isNaN(+value)) {
    callback(new Error('请输入合法数字'));
  } else {
    callback()
  }
}
export default {
  created () {
    this.fetchData()
  },
  data () {
    return {
      tagNo: this.$route.params.tagNo,
      flag: 0,
      modelShow: false,
      catalogueArr: [], // 章节的数组
      addCatalogueType: 1, // 新增章节类型
      drag: false, // 拖拽
      dragArr: [], // 拖拽的数组
      dragItem: {}, //
      ruleForm: {
        title: '',
        sort: 0,
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      
      rules: {
        title: [
          { required: true, message: '请输入章节名称', trigger: 'blur' },
        ],
        sort: [
        { required: true, validator: validateSort ,trigger: 'change' },
      ],
      },
    };
  },
  components: { UpdataImg },
  methods: {
    // 新增章节按钮
    addCatalogueClick () {
      this.flag = 1
      this.modelShow = true
    },
    // 编辑按钮
    editClick (index, item) {
      this.ruleForm = item
      this.modelShow = true
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          +this.ruleForm.catalogueNo ? this.edit() : this.add()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 添加
    async add () {
      const params = {
        tagNo: this.tagNo,
        title: this.ruleForm.title,
        sort: +this.ruleForm.sort
      }
      const { code } = await studyCatalogueSave(params)
      if (code !== 200) return
      message.success('添加成功')
      this.handleCancel()
      this.fetchData()
    },
    // 编辑
    async edit () {
      const params = {
        ...this.ruleForm,
        sort: +this.ruleForm.sort
      }
      const { code } = await studyCatalogueEdit(params)
      if (code !== 200) return
      message.success('修改成功')
      this.handleCancel()
      this.fetchData()
    },

    handleCancel () {
      this.modelShow = false
      // 取消/关闭
      this.ruleForm = {
        title: '',
        sort: 0,
      }
    },
    // 章节目录删除按钮
    catalogueDeleteClick (arr) {
      if (arr.lessons.length > 0) {
        Modal.confirm({
            title: "提示",
            content: "此章节下面有课节,不能删除",
            okText: "确认",
            okType: "danger",
            cancelText: "取消",
        });
      } else {
        Modal.confirm({
          title: "提示",
          content: "删除后相关数据无法恢复, 是否确认删除",
          okText: "确认",
          okType: "danger",
          cancelText: "取消",
          onOk: async () =>{
            const { code} = await studyTagCatalogueDel({ catalogueNo: arr.catalogueNo })
            if (code !== 200) return  
            message.success("删除目录成功");
            this.catalogueArr = this.catalogueArr.filter(
              (item) => item.catalogueNo !== arr.catalogueNo
            );  
          },
        });
      }
    },
    async fetchData () {
      const params = {
        withLessons: 1,
        tagNo: this.tagNo
      }
      const {code ,data} = await studyCatalogueList(params)
      if (code !== 200) return
      console.log(111)
      this.catalogueArr = data
    },
  },
};
</script>

<style lang="less" scoped>
/*定义要拖拽元素的样式*/
.ghostClass {
    background-color: #f2f2f2 !important;
}
.chosenClass {
    background-color: #f2f2f2 !important;
    opacity: 1 !important;
}
.dragClass {
    background-color: #f2f2f2 !important;
    opacity: 1 !important;
    box-shadow: none !important;
    outline: none !important;
    background-image: none !important;
}
.col {
    flex: 1;
    border-radius: 5px;
    background: #fff;
}
.drag-form {
    /deep/ span {
        display: block;
        min-height: 1px;
    }
}
.my_icon {
    font-size: 26px;
    margin-right: 20px;
}

.list {
    margin-bottom: 10px;
    .big_list {
        width: 100%;
        height: 60px;
        line-height: 60px;
        background-color: #f2f2f2;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border: 1px solid #cccccc59;
        .list_left {
            margin-left: 20px;
            min-width: 270px;

            .input {
                position: relative;

                span {
                    position: absolute;
                    top: -22px;
                    right: 6px;
                }
            }
        }
        .list_right{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    .small_list {
        border: 1px solid #cccccc59;
        border-top: none;
        width: 100%;
        height: 50px;
        line-height: 50px;
        display: flex;
        justify-content: space-between;
        .small_list_left {
            margin-left: 20px;
        }
        .small_list_right {
            margin-right: 20px;
        }
    }
}

// 新增章节的输入框
.add_input {
    position: relative;
    span {
        position: absolute;
        top: 0;
        right: 6px;
    }
}
.input_box {
    width: 70%;
    height: 60px;
    line-height: 60px;
    background-color: #f2f2f2;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ant-input {
        width: 400px;
        margin-left: 20px;
    }
}
// 新增章节
.add_chapter {
    width: 100%;
    height: 60px;
    line-height: 60px;
    background-color: #f2f2f2;
    padding-left: 20px;
    .add {
        font-size: 20px;
        color: #409eff;
        margin-right: 10px;
    }
}
</style>
