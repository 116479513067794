<template>
  <!-- 课节管理组件 -->
  <div>
    <!-- 顶部 -->
    <div class="top">
      <div class="top_left">
        <a-button icon="plus" type="primary" @click="showAddModal({})">
          添加课节
        </a-button>
      </div>
      <div class="top_rigiht">
        <a-input
          ref="userNameInput"
          v-model="keywords"
          placeholder="请输入课节名称"
          style="width: 200px"
          @pressEnter="searchClick"
        >
          <a-icon slot="prefix" type="search" />
        </a-input>
        <a-button
          icon="search"
          type="primary"
          @click="searchClick"
          :disabled="isDisableSearch"
          >搜索</a-button
        >
      </div>
    </div>
    <!-- 表格 -->
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      :loading="tableLoading"
      row-key="lessonsNo"
    >
      <span slot="number" slot-scope="number, record, index">
        <span>{{ index + 1 }}</span>
      </span>
      
      <span slot="operation" slot-scope="operation, record">
        <a class="margin_right20" @click="showAddModal(record)"
          >编辑</a
        >
        <a class="margin_right20" @click="delClick(record)">删除</a>
      </span>
    </a-table>
    
    <!-- 分页 -->
    <MyPagination
      :count="count"
      :page="pageNo"
      :showSizeChanger="false"
      @showSizeChangeFn="showSizeChangeFn"
      v-show="!tableLoading"
    />
    <AddLessonModal :visible="ModalVisible" :info="curRecord" @fetch="fetchData" @close="() => ModalVisible = false" />
  </div>
</template>

<script>
import { message, Modal } from "ant-design-vue";
import {
  studyTagsLessonList,
  studyTagsLessonDel,
  studyCatalogueList
} from "@/request/api/coursManage";
import MyPagination from "@/components/pagination/MyPagination";
import AddLessonModal from './AddLessonModal.vue';
import { StudyContentLessonColumns } from '../../constant'
export default {
  components: { MyPagination, AddLessonModal },
  created() {
    this.fetchData()
  },
  data() {
    return {
      catalogueTree: [],
      catalogueFlag: false,
      ModalVisible: false,
      pageNo: 1,
      pageSize: 20,
      tagNo: this.$route.params.tagNo,
      keywords: "", // 搜索的值
      isDisableSearch: false, // 是否禁用搜索框
      count: 0, // 表格数组的总条数
      tableLoading: true, // 表格是否加载
      // 表格数据
      columns: StudyContentLessonColumns,
      tableData: [],
      curRecord: {},
      lessonNoList: []
    };
  },
  methods: {
    async getSelectTree() {
      const params = {
        withLessons: 0,
        tagNo: this.tagNo
      }
      const {code ,data} = await studyCatalogueList(params)
      if (code !== 200) return
      this.catalogueTree = data
    },
    //   搜索的按钮
    searchClick() {
      this.isDisableSearch = true;
      setTimeout(() => {
        this.isDisableSearch = false;
      }, 3000);
      this.page = 1;
      this.fetchData();
    },
    // 编辑的按钮
    showAddModal(record) {
      this.curRecord = record
      this.ModalVisible = true
    },
    // 删除的按钮
    delClick(record) {
      Modal.confirm({
        title: "提示",
        content: "删除后相关数据无法恢复, 是否确认删除",
        okText: "确认",
        okType: "danger",
        cancelText: "取消",
        onOk: async () => {
          const { code } = await studyTagsLessonDel({ id: record.id})
          if (code !== 200)  return
          message.success("删除成功");
          this.tableData = this.tableData.filter(
            (item) => item.lessonsNo !== record.lessonsNo
          );
        },
      });
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize;
      this.fetchData();
    },
    // 获取课节列表的函数
    async fetchData() {
      const params = {
        tagNo: this.tagNo,
        page: this.pageNo,
        pageSize: this.pageSize,
        keywords: this.keywords, // 课节名称
      }
      const { code, data } = await studyTagsLessonList(params)
      this.tableLoading = false;
      if (code !== 200 ) return
      this.tableData = data.lessonsList;
      this.count = data.count
    }
  }
}
</script>
 
<style lang="less" scoped>
.top {
  display: flex;
  justify-content: space-between;
  .top_rigiht {
    span {
      margin-right: 10px;
    }
  }
  .ant-select {
    margin-right: 10px;
  }
}
// 弹框
.tips {
  text-align: center;
  margin: 20px 0;
  font-size: 20px;
  font-weight: 600;
  .anticon {
    font-size: 40px;
    margin-right: 20px;
    color: #ff0000;
  }
}
.text {
  margin-top: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.del_btn {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  .ant-btn {
    margin-left: 20px;
  }
}
.share {
  width: 95%;
  text-align: center;
  height: 30px;
  line-height: 30px;
  background-color: #efefef;
}
/deep/.ant-select-dropdown-placement-bottomLeft {
  top: 96px !important;
}
</style>