<template>
  <!-- 内容管理页面 -->
  <div style="margin-top: -20px">
    <a-tabs :activeKey="activeKey" @change="callback">
      <a-tab-pane
        :key="1"
        tab="章节管理"
        v-if="catalogueFlag"
      >
        <ChapterManage v-if="activeKey == 1"/>
      </a-tab-pane>
      <a-tab-pane :key="0" tab="课节管理">
        <LessonsManage
          :catalogueFlag="catalogueFlag"
        />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import ChapterManage from "./components/ChapterManage";
import LessonsManage from "./components/LessonsManage";
export default {
  components: { ChapterManage, LessonsManage },
  data() {
    return {
      activeKey: null,
      catalogueFlag: this.$route.query.catalogueFlag === 'true', // 是否分章节
      courseNo: this.$route.params.courseNo, // 课程编号
    };
  },
  created() {
    this.activeKey = this.catalogueFlag ? 1 : 0
  },
  methods: {
    // 点击切换tab栏
    callback(key) {
      this.activeKey = key;
      localStorage.setItem("activeKey", key);
    },
  }
};
</script>
 
<style lang="less" scoped>
.ant-tabs {
  margin-top: 20px;
}
</style>